.adv-modal-floating,
.adv-modal {
    .modal-product-video {
        min-width: 750px;
        min-height: 470px;
        max-width: 1000px;
        max-height: 700px;

        .modal-content {
            box-sizing: border-box;
            width: 100%;
        }

        .carousel-product-video-in-modal--wrap {
            flex-grow: 1;
        }

        .products-view-video-wrap {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
        }

        .carousel-inner {
            margin: 0 auto;
        }

        .video-embed {
            margin-top: 15px;
        }
    }
}


@media(max-width: 750px) {
    .modal-product-video {
        min-width: 480px;
        min-height: 310px;
    }
}


@media(max-width: 895px) {
    .modal-product-video {
        width: 100%;
    }
}

/*product video*/
.video-embed {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .embed-container {
        position: static;
        padding-bottom: 0;
    }
}
