.price-amount-list {
    color: #666666;

    &__row {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        background-color: #FFF;

        &:nth-child(odd) {
            background-color: #F9F9F9;
        }

        &:first-child {
            border-top-left-radius: 1px;
            border-top-right-radius: 1px;
        }

        &:last-child {
            border-bottom-left-radius: 1px;
            border-bottom-right-radius: 1px;
        }
    }

    &__col {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 2px;
        font-size: 12px;
        line-height: 1.5;

        &--head {
            font-weight: 600;
        }
    }
}

.price-block {
    .price-block__old-price-title {
        padding: 0 0 5px 0;
    }

    .price-block__old-price {
        text-decoration: line-through;
    }

    .price-block__new-price-title {
        padding: 13px 0 5px 0;
    }
}

.mobile-product-view-item__inner {
    .price-amount-list {
        padding: 15px 0 0 0;

        &__col {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}
