@import "../../../../styles/_settings.scss";
@import "../../../../styles/functions/_util.scss";

$quickviewArrowWidth: rem-calc(60px);

.quickview-arrows-prev,
.quickview-arrows-next {
    font-size: rem-calc(40px);
    position: absolute;
    background: none;
    border: 0;
    box-sizing: border-box;
    width: $quickviewArrowWidth;
    display: flex;
    top: -43px;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    justify-content: center;
    
    &:focus {
        outline: none;
    }

    &:before {
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
        margin:0;
    }
}

.quickview-arrows-prev {
    margin-left: -50px;
}
.quickview-arrows-next {
    margin-left: 50px;
}
@media(min-width: 1180px) {

    .quickview-arrows-prev,
    .quickview-arrows-next {
        font-size: rem-calc(50px);
        top: 50%;
        left: auto;
        transform: translateY(-50%);
    }

    .quickview-arrows-next {
        left: 100%;
        margin-left: $margin-value-base;
    }

    .quickview-arrows-prev {
        right: 100%;
        margin-left:auto;
        margin-right: $margin-value-base;
    }
}

.adv-modal .modal-quickview {
    max-width: $max-width - (($quickviewArrowWidth + $margin-value-base) * 2);
    width: 100%;
    height: auto; /* не подгружает контент в айфоне поэтому auto*/
}

.modal-quickview {
    .details-availability, .details-rating {
        display: inline-block;
        vertical-align: middle;
    }

    .details-rating {
        margin-left: $margin-value-base;
    }

    min-height: 540px;
}

.quickview-loading-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (min-height: 768px) {
    .adv-modal .modal-quickview {
        max-width: $min-width - (($quickviewArrowWidth + $margin-value-base) * 2);
        min-width: $min-width - (($quickviewArrowWidth + $margin-value-base) * 2);
    }
}
